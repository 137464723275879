import React from "react"
import { onlineSignaturePage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const OnlineSignature = () => {
  return (
    <Layout>
      <Seo
        title="Fast & Secure E-Signatures for Accountants in Australia"
        description={`Simplify practice management for Australian accountants using Synkli’s secure e-signature. Collect signatures fast on financial reports and tax documents.`}
      />

      <FeatureDetailsPage data={onlineSignaturePage} />
      
    </Layout>
  )
}

export default OnlineSignature
